var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "page-box" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pageType === "list",
                  expression: "pageType === 'list'",
                },
              ],
              staticClass: "page-type1",
            },
            [
              _c("div", { staticClass: "list-box" }, [
                _c("div", { staticClass: "banner-box" }, [
                  _c("img", {
                    staticClass: "banner-img",
                    attrs: {
                      src:
                        _vm.bannerImg ||
                        "https://img-fe.tengzhihh.com/image/7ca26b7201d131-750x176.png",
                    },
                  }),
                ]),
                _c("div", { staticClass: "options-area f fc" }, [
                  _c(
                    "div",
                    {
                      class: `option-btn ${
                        _vm.currentPlayingMode === 0 ? "current" : ""
                      }`,
                      on: {
                        click: function ($event) {
                          return _vm.changeCurrentPlayingMode(0)
                        },
                      },
                    },
                    [_vm._v(" 开始播放 ")]
                  ),
                  _c(
                    "div",
                    {
                      class: `option-btn ${
                        _vm.currentPlayingMode === 1 ? "current" : ""
                      }`,
                      on: {
                        click: function ($event) {
                          return _vm.changeCurrentPlayingMode(1)
                        },
                      },
                    },
                    [_vm._v(" 随机播放 ")]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "list-area f frd flex-wrap" },
                  [
                    _vm._l(_vm.musicList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "list-item f fv fc",
                          on: {
                            click: function ($event) {
                              return _vm.clickListItem(item, index)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              staticClass: "cover",
                              attrs: {
                                src:
                                  _vm.indexMode === "western"
                                    ? "https://img-fe.tengzhihh.com/image/960f9c5a350e7b-210x210.png"
                                    : "https://img-fe.tengzhihh.com/image/6bc78acb44741f-210x210.png",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "clamp1 name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    }),
                    _vm._l(3 - (_vm.musicList.length % 3), function (item) {
                      return _c("i", { staticClass: "i-tag" })
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.currentMusicId &&
                        _vm.playerType === "music" &&
                        _vm.currentMusic.name,
                      expression:
                        "currentMusicId && playerType === 'music' && currentMusic.name",
                    },
                  ],
                  staticClass: "audio-box f fc",
                },
                [
                  _c("img", {
                    staticClass: "playing-img",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/e611b3c86ac896-70x70.png",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changePageType("playing")
                      },
                    },
                  }),
                  _c("div", { staticClass: "player-box clamp1" }, [
                    _vm._v(" " + _vm._s(_vm.currentMusic.name) + " "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "control-box",
                      on: { click: _vm.playControl },
                    },
                    [
                      _c("van-circle", {
                        staticClass: "circle-out",
                        attrs: {
                          value: _vm.currentRate,
                          color: "#FFC600",
                          "layer-color": "#D6D3D6",
                          "stroke-width": 60,
                          rate: 30,
                        },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isPlaying,
                            expression: "!isPlaying",
                          },
                        ],
                        staticClass: "circle",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/dfd676ac3fb6e0-24x24.png",
                        },
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isPlaying,
                            expression: "isPlaying",
                          },
                        ],
                        staticClass: "circle",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/05bb2d0ee92216-24x24.png",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("img", {
                    staticClass: "list-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/124ecf7b97df9c-56x56.png",
                    },
                    on: { click: _vm.showMusicList },
                  }),
                ]
              ),
            ]
          ),
          _c("Playing", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageType === "playing",
                expression: "pageType === 'playing'",
              },
            ],
            ref: "playingRef",
            attrs: {
              serialId: _vm.routeQuery.serial_id,
              lessonId: _vm.lessonId,
              lessonCategoryId: _vm.lessonCategoryId,
              from: _vm.from,
              currentMusic: _vm.currentMusic,
              currentRate: _vm.currentRate,
              currentPosition: _vm.currentPosition,
              isPlaying: _vm.isPlaying,
              currentPlayingMode: _vm.currentPlayingMode,
              showLyric: _vm.showLyric,
              indexMode: _vm.indexMode,
              bgImg: _vm.bgImg,
            },
            on: {
              showMusicList: _vm.showMusicList,
              playControl: _vm.playControl,
              currentPositionChange: _vm.currentPositionChange,
              closePlayingMusicList: _vm.closePlayingMusicList,
              changeCurrentPlayingMode: _vm.playingChangeCurrentPlayingMode,
              changeShowLyric: _vm.changeShowLyric,
              back: _vm.back,
            },
          }),
          _c("PlayList", {
            ref: "playListRef",
            attrs: {
              serialId: _vm.routeQuery.serial_id,
              lessonId: _vm.lessonId,
              isPlaying: _vm.isPlaying,
              from: _vm.from,
              currentMusic: _vm.currentMusic,
              currentPlayingList: _vm.currentPlayingList,
              currentPlayingMode: _vm.currentPlayingMode,
            },
            on: {
              removeMusicFromList: _vm.removeMusicFromList,
              changeCurrentPlayingMode: _vm.playingChangeCurrentPlayingMode,
            },
          }),
          _c("Dialog", { ref: "dialog" }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "loading-box f fc fvc" },
        [_c("van-loading", { attrs: { type: "spinner" } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }