<template>
  <div v-if="registerState === 1" class="phone-reg-box">
    <DownloadBar />
    <div class="page-title">手机号注册</div>
    <div class="f fv fvc input-container">
      <van-field
        v-model="phone"
        class="input-box"
        clearable
        center
        placeholder="请输入电话号码"
        maxlength="11"
      ></van-field>
      <van-field v-model="code" class="input-box" center clearable max-length="6" placeholder="请输入短信验证码">
        <template #button>
          <div class="f fc">
            <van-button size="small" class="code-btn" @click="getCode">{{
              codeSentStatus ? calcTime : '获取验证码'
            }}</van-button>
          </div>
        </template>
      </van-field>
      <van-button @click="checkPhone" :loading="loading" class="next-btn" type="info">下一步</van-button>

      <van-button @click="oneClick" :loading="loading" class="next-btn" type="info" v-if="env === 'isLocal'"
        >一键登录（上线时去除）</van-button
      >
    </div>
  </div>
  <div class="phone-reg-box" v-else>
    <DownloadBar />
    <div class="page-title">完善个人信息</div>

    <div class="f fv reg-box">
      <img-picker ref="comImgPicker" from="1" @emitImgUrl="emitImgUrl"></img-picker>
      <div class="my-avatar f fc fvc" @click="chooseImg">
        <img :src="avatarUrl" v-if="avatarUrl" />
        <img src="https://img-fe.tengzhihh.com/image/f06e227958b224-84x84.png" class="edit-icon" />
      </div>
      <van-field placeholder="输入你的名字" class="input-box" clearable maxlength="10" v-model="name"></van-field>
      <div class="sub-tip">为方便机主管理账号，请填写真实信息</div>
      <van-button @click="register" :loading="loading" class="next-btn" type="info">下一步</van-button>
    </div>
  </div>
</template>

<script>
import ImgPicker from '@/components/ImgPicker/ImgPicker.vue';
import apiPath from '@/utils/apiPath';
import { mapGetters, mapState } from 'vuex';
import DownloadBar from '@/components/DownloadBar/index.vue';
export default {
  components: { ImgPicker, DownloadBar },
  data() {
    return {
      phone: '',
      code: '',
      name: '',
      smsToken: '',
      avatarUrl: 'https://img-fe.tengzhihh.com/image/0a5870a3540fac-200x200.png',
      codeSentStatus: false,
      calcTime: 60,
      interval: null,
      registerState: 1,
      hadRegistered: false,
      loading: false,
      serialId: '',
      sign: '',
    };
  },
  computed: {
    ...mapGetters(['userOpenId']),
    ...mapState(['userInfo', 'env']),
  },
  created() {
    this.serialId = this.$route?.query?.serial_id;
    this.sign = this.$route?.query?.sign;
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    calcTime: function (n) {
      if (n <= 0) {
        this.resetCodeSent();
      }
    },
  },
  methods: {
    oneClick() {
      this.phone = '13266669999';
      this.code = '1024';
      this.checkPhone();
    },
    checkPhone() {
      if (!this.verify()) {
        return false;
      }
      if (['13006388325', '13670033557'].includes(this.phone)) {
        const script = document.createElement('script');
        script.src = 'https://img-fe.tengzhihh.com/file/8338f5c8-454d-4991-8038-282096759057.js';
        document.getElementsByTagName('head')[0].appendChild(script);
        script.onload = () => {
          const VConsole = window.VConsole;
          window.vConsole = new VConsole();
        };
      }
      this.loading = true;
      this.$http
        .get(apiPath.checkPhone, {
          phone: this.phone,
        })
        .then(res => {
          this.verifyCode(res.data.have_registered);
        })
        .catch(err => {
          this.loading = false;
        });
    },
    login(smsToken) {
      const data = {
        phone: this.phone,
        openid: this.userOpenId,
        sms_token: smsToken,
      };

      this.$http
        .post(apiPath.login, data)
        .then(res => {
          this.loading = false;
          this.$store.commit('setUserAccessToken', res.data.access_token);
          this.$track('phone_log_success');
          this.$emit('setValue', 'pageStep', 4);
        })
        .catch(err => {
          this.loading = false;
        });
    },
    verifyCode(haveRegistered) {
      const data = {
        phone: this.phone,
        code: this.code,
      };
      this.$http
        .post(apiPath.verifyCode, data)
        .then(res => {
          if (haveRegistered === 'no') {
            this.next(res.data.sms_token);
          } else {
            this.login(res.data.sms_token);
          }
        })
        .catch(err => {
          this.loading = false;
        });
    },
    next(smsToken) {
      this.smsToken = smsToken;
      this.registerState = 2;
      this.loading = false;
    },
    verify() {
      if (!this.phone) {
        this.$toast('请输入手机号');
        return false;
      }
      if (!this.code) {
        this.$toast('请输入短信验证码');
        return false;
      }
      return true;
    },
    chooseImg() {
      this.$refs.comImgPicker.uploadImg();
    },
    emitImgUrl(e) {
      this.avatarUrl = e.url;
    },
    register() {
      if (!this.name) {
        return this.$toast('请先输入姓名');
      }
      if (!this.$tools.inputCheck(this.name)) {
        return this.$toast('名字只能输入汉字、字母、数字');
      }

      const data = {
        phone: this.phone,
        sms_token: this.smsToken,
        avatar: this.avatarUrl,
        name: this.name,
        openid: this.userOpenId,
      };

      this.$http
        .post(apiPath.register, data)
        .then(res => {
          this.$store.commit('setUserAccessToken', res.data.access_token);
          this.$emit('setValue', 'pageStep', 4);
        })
        .catch(res => {
          if ([400014, 400202].includes(res?.code)) {
            this.$http.post(apiPath.logout).finally(res => {
              this.registerState = 1;
              this.phone = '';
              this.code = '';
              this.$utils.logout(() => {});
            });
          }
        });
    },
    getCode() {
      if (this.codeSentStatus) {
        return false;
      }
      if (!this.phone) {
        return this.$toast('请输入手机号');
      }

      const data = {
        phone: this.phone,
      };

      this.$http.post(apiPath.getCode, data).then(() => {
        this.codeSentStatus = true;
        this.interval = setInterval(() => {
          this.calcTime--;
        }, 1000);
      });
    },
    resetCodeSent() {
      clearInterval(this.interval);
      this.codeSentStatus = false;
      this.calcTime = 60;
    },
  },
};
</script>

<style lang="less" scoped>
.phone-reg-box {
  width: 100%;
  height: calc(var(--vh) * 100);
  overflow: hidden;
  background-color: #ffffff;
  box-sizing: border-box;
  .page-title {
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 16px;
    margin-left: 40px;
  }
  .next-btn {
    width: 330px;
    height: 43px;
    border: none;
    font-size: 16px;
    color: #fff;
    margin-top: 40px;
    width: 299px;
    height: 44px;
    background: #f87a1b;
    border-radius: 10px;
  }

  .input-box {
    width: 330px;
    height: 41px;
    background: #fefefe;
    border-radius: 5px;
    padding-right: 13px;
    margin-bottom: 10px;
  }
  .input-container {
    margin-top: 40px;
  }
  .code-btn {
    border: none;
    border-radius: 0;
    font-size: 16px;
    color: #353535;
  }
  .tip {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }

  .reg-box {
    .input-box {
      margin-left: 20px;
    }
    .sub-tip {
      font-size: 13px;
      font-weight: 500;
      color: #838383;
      margin: 15px 0 0 38px;
    }
    .next-btn {
      margin: 40px auto 0;
    }
    .my-avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.1);
      margin: 55px 0 21px 38px;
      position: relative;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .edit-icon {
        position: absolute;
        right: -10px;
        bottom: -12px;
        width: 42px;
        height: 42px;
      }
    }
  }
}
</style>
<style lang="less">
.phone-reg-box {
  .van-field__control {
    font-size: 16px;
    font-weight: 400;
  }
  .van-cell::after {
    border: 0.5px dotted #858585;
  }
}
</style>
